class AnnouncementQueue {
  constructor() {
    this._started = false
    this._announcementCallbacks = new Map()
  }

  static getInstance() {
    if (!this._instance) {
      this._instance = new AnnouncementQueue()
    }

    return this._instance
  }

  announceNext() {
    this._announce({ wait: false })
  }

  startAnnouncing() {
    // To avoid multiple calls to the announce method
    // We will only need to ignite the announcement once
    if (this._started) return

    this._started = true
    this._announce({ wait: true })
  }

  on(eventName, { show: showCallback, hide: hideCallback }) {
    const eventCallbacks = this._announcementCallbacks.get(eventName) || []

    eventCallbacks.push({
      status: "pending",
      show: showCallback,
      hide: hideCallback
    })
    this._announcementCallbacks.set(eventName, eventCallbacks)
  }

  _announce(options = { wait: true }) {
    if (options.wait) {
      this._waitOnAnnounceCallbacks(5)
    }

    const onAnnounceCallbacks = this._announcementCallbacks.get("announce") || []
    this._updateLastAnnounceCallback(onAnnounceCallbacks)
    this._runCurrentAnnounceCallback(onAnnounceCallbacks)
  }

  _updateLastAnnounceCallback(onAnnounceCallbacks) {
    const lastAnnounceCallback = onAnnounceCallbacks.find((callback) => {
      return callback.status === "running"
    })

    if (lastAnnounceCallback) {
      lastAnnounceCallback.status = "done"
      lastAnnounceCallback.hide()
    }
  }

  _runCurrentAnnounceCallback(onAnnounceCallbacks) {
    const currentAnnounceCallback = onAnnounceCallbacks.find((callback) => {
      return callback.status === "pending"
    })

    if (currentAnnounceCallback) {
      currentAnnounceCallback.status = "running"
      currentAnnounceCallback.show()
    }
  }

  // It will recursively check if there are any callbacks registered within a certain time frame for given attempts
  _waitOnAnnounceCallbacks(attempts) {
    const waitTime = attempts * 500
    const onAnnounceCallbacks = this._announcementCallbacks.get("announce") || []

    // If there are at least one callback registered, we can early return to the announce method
    if (onAnnounceCallbacks.length > 0) {
      return this._announce({ wait: false })
    }

    // If we have reached the maximum attempts, we can early return. Nothing to be shown.
    if (waitTime <= 0) {
      return
    }

    // Otherwise, we will recursively check if there are any callbacks registered within a 500ms time frame
    setTimeout(() => {
      this._waitOnAnnounceCallbacks(waitTime - 500)
    }, waitTime)
  }
}

export default AnnouncementQueue.getInstance()
export const AnnouncementQueueConstructor = AnnouncementQueue
