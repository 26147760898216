export default class IosMessageHandler {
  constructor() {
    this.pendingRequests = {}
  }

  // This method encapsulates the logic to send a message to the iOS app
  // by using the message as the action and the generated requestId as the key
  // to store the pending request callback so it can be handled later
  // once the iOS app responds using the same requestId
  request(message) {
    const requestId = this.generateRequestId()
    return new Promise((resolve, reject) => {
      this.pendingRequests[requestId] = { resolve, reject }
      console.log("Sending request to iOS app", message)
      this.pushNotificationsHandler?.postMessage({
        action: message,
        requestId: requestId
      })
    }).finally(() => {
      delete this.pendingRequests[requestId]
    })
  }

  send(message, data) {
    console.log("Sending one-way message to iOS app", message, data)

    this.pushNotificationsHandler?.postMessage({
      action: message,
      data: data
    })
  }

  // This method encapsulates the logic to call the registered pending request callback
  // by using the requestID as the key to find the callback
  // and forwarding the remaining arguments to the callback
  handleResponse(requestId, result, error) {
    const callback = this.pendingRequests[requestId]
    if (!callback) {
      console.error("Request not found")
      return
    }

    if (error) {
      callback.reject(error)
    } else {
      callback.resolve(result)
    }

    delete this.pendingRequests[requestId]
  }

  isPushChannelAvailable() {
    return !!this.pushNotificationsHandler
  }

  // iOS injects a global object `webkit` with a property `messageHandlers`
  // and the key is the name of the message handler: 'pushNotifications'
  get pushNotificationsHandler() {
    return window?.webkit?.messageHandlers?.pushNotifications
  }

  // This method generates a random string to be used as the requestId
  generateRequestId() {
    return Math.random().toString(36).substring(7)
  }
}
