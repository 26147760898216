import { Controller } from "@hotwired/stimulus"

const upKey = 38
const downKey = 40

export default class extends Controller {
  static targets = ["input", "decrease", "increase"]

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === "value") {
        this.inputTarget.value = this.state.value
        this.inputTarget.dispatchEvent(new Event("change"))

        this.enableButtons()

        if (this.isReachedMin) this.disableDecreaseButton()
        if (this.isReachedMax) this.disableIncreaseButton()
      }

      return true
    }
  }

  initialize() {
    this.state = new Proxy({}, this.renderer)
    this.propose()
    this.commit()
  }

  commit(event) {
    this.state.value = this.state.proposedValue
  }

  decrease(event) {
    if (event) event.preventDefault()

    if (this.isDisabled) return
    if (this.isReachedMin) return

    this.proposeDecrease()
    this.commit()
  }

  increase(event) {
    if (event) event.preventDefault()

    if (this.isDisabled) return
    if (this.isReachedMax) return

    this.proposeIncrease()
    this.commit()
  }

  propose(event) {
    const proposedValue = this.parsedInputValue

    if (proposedValue !== undefined) this.state.proposedValue = proposedValue

    if (this.isReachedMax) this.state.proposedValue = this.max
    if (this.isReachedMin) this.state.proposedValue = this.min
  }

  vary(event) {
    switch (event.keyCode) {
      case upKey:
        event.preventDefault()

        this.propose()
        this.increase()

        break
      case downKey:
        event.preventDefault()

        this.propose()
        this.decrease()

        break
    }
  }

  proposeDecrease() {
    this.state.proposedValue -= this.step
  }

  proposeIncrease() {
    this.state.proposedValue += this.step
  }

  enableButtons() {
    [this.decreaseTarget, this.increaseTarget].forEach(el => el.classList.remove("button--disabled"))
  }

  disableDecreaseButton() {
    this.decreaseTarget.classList.add("button--disabled")
  }

  disableIncreaseButton() {
    this.increaseTarget.classList.add("button--disabled")
  }

  get parsedInputValue() {
    return this.parseInt(this.inputTarget.value, 0)
  }

  get step() {
    return this.parseInt(this.inputTarget.getAttribute("step"), 1)
  }

  get min() {
    return this.parseInt(this.inputTarget.getAttribute("min"), null)
  }

  get max() {
    return this.parseInt(this.inputTarget.getAttribute("max"), null)
  }

  get isReachedMin() {
    return this.min && this.nextDecreasedValue < this.min
  }

  get isReachedMax() {
    return this.max && this.nextIncreasedValue > this.max
  }

  get nextIncreasedValue() {
    return this.state.proposedValue + this.step
  }

  get nextDecreasedValue() {
    return this.state.proposedValue - this.step
  }

  get isDisabled() {
    return this.element.classList.contains("stepper-field--disabled")
  }

  parseInt(originalValue, defaultValue) {
    if (originalValue == "") return

    const value = parseInt(originalValue?.match(/-?\d+/g)?.join(""))
    return Number.isNaN(value) ? defaultValue : value
  }
}
