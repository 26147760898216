import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  validate(event) {
    if (!this.formTarget.checkValidity()) {
      event.stopImmediatePropagation()
    }
  }
}
