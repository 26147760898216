// Based on this gist: https://gist.github.com/Klerith/80abd742d726dd587f4bd5d6a0ab26b6
// Reference of use: https://web.dev/articles/push-notifications-subscribing-a-user#what_is_a_pushsubscription
export default function(base64String) {
  // padding with repeated '=' to make the length a multiple of 4
  const padding = "=".repeat((4 - base64String.length % 4) % 4)
  // replace characters according to base64url specifications (see https://tools.ietf.org/html/rfc4648#section-5)
  // and add padding to the base64 string
  const base64 = (base64String + padding)
    .replace(/-/g, "+")
    .replace(/_/g, "/")

  // convert base64 string to binary string
  const rawData = window.atob(base64)
  // create a Uint8Array in the size of the binary string
  const outputArray = new Uint8Array(rawData.length)

  // loop through the size of the binary string and for each character
  // convert it to a number and store it in the Uint8Array
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }

  return outputArray
}
