import { Controller } from "@hotwired/stimulus"

const documentClick = _event => {
  document.body.dispatchEvent(new Event("click", { bubbles: true, cancelable: true, composed: true }))
}

// ajax requests prevent the event propagation. Some of our components depend on `click@document` events.
// So, we allow the clicks to propagate to the document. It is safer to directly propagate these to the
// document.body as some existing code might depend on the event being stopped before reaching the parent.
export default class extends Controller {
  connect() {
    this.element.addEventListener("ajax:before", documentClick)
  }

  disconnect() {
    this.element.removeEventListener("ajax:before", documentClick)
  }
}
