import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    automatic: Boolean
  }

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === "closestEdge") {
        if (this.state.closestEdge === "top") this.renderer.positionWhenCloseToTop()
        if (this.state.closestEdge === "bottom") this.renderer.positionWhenCloseToBottom()
        if (this.state.closestEdge === "none") this.renderer.positionWhenCloseToTop()
      }

      if (property === "arrowPosition") {
        if (this.state.arrowPosition === "left") this.renderer.alignToLeft()
        if (this.state.arrowPosition === "center") this.renderer.alignToCenter()
        if (this.state.arrowPosition === "right") this.renderer.alignToRight()
      }

      return true
    },

    positionWhenCloseToTop: () => {
      this.element.classList.replace("tooltip--arrow-bottom-left", "tooltip--arrow-top-left")
      this.element.classList.replace("tooltip--arrow-bottom-center", "tooltip--arrow-top-center")
      this.element.classList.replace("tooltip--arrow-bottom-right", "tooltip--arrow-top-right")

      this.element.style.top = `${this.togglerElement.offsetHeight + this.arrowSize}px`
    },

    positionWhenCloseToBottom: () => {
      this.element.classList.replace("tooltip--arrow-top-left", "tooltip--arrow-bottom-left")
      this.element.classList.replace("tooltip--arrow-top-center", "tooltip--arrow-bottom-center")
      this.element.classList.replace("tooltip--arrow-top-right", "tooltip--arrow-bottom-right")

      this.element.style.top = `-${this.elementHeight + this.arrowSize}px`
    },

    alignToLeft: () => {
      this.element.style.left = `${this.togglerElement.offsetLeft}px`
    },

    alignToCenter: () => {
      const elementWidth = this.element.offsetWidth
      const togglerWidth = this.togglerElement.offsetWidth
      const togglerLeft = this.togglerElement.offsetLeft
      var leftPosition
      if (togglerWidth > elementWidth) {
        leftPosition = togglerLeft + parseInt((togglerWidth - elementWidth) / 2)
      } else {
        leftPosition = togglerLeft - parseInt((elementWidth - togglerWidth) / 2)
      }
      this.element.style.left = `${leftPosition}px`
    },

    alignToRight: () => {
      const elementWidth = this.element.offsetWidth
      const togglerWidth = this.togglerElement.offsetWidth
      const togglerLeft = this.togglerElement.offsetLeft

      var leftPosition = togglerLeft + togglerWidth - elementWidth

      this.element.style.left = `${leftPosition}px`
    }
  }

  initialize() {
    this.state = new Proxy({}, this.renderer)
  }

  connect() {
    this.setup()
  }

  setup(event) {
    if (!this.togglerElement || !this.isAutomatic) return

    if (this.distanceToTop < this.elementHeightWithBuffer) {
      this.state.closestEdge = "top"
    } else if (this.distanceToBottom < this.elementHeightWithBuffer) {
      this.state.closestEdge = "bottom"
    } else {
      this.state.closestEdge = "none"
    }

    this.state.arrowPosition = this.arrowPosition
  }

  get togglerElement() {
    if (this.elementID == undefined) return undefined

    return document.querySelector(`[data-toggle-selector-value~="#${this.elementID}"]`)
  }

  get elementID() {
    return this.element.id
  }

  get elementHeight() {
    return this.element.offsetHeight
  }

  get distanceToBottom() {
    return window.innerHeight - this.togglerElement.getBoundingClientRect().bottom
  }

  get distanceToTop() {
    return this.togglerElement.getBoundingClientRect().top
  }

  get arrowPosition() {
    return this.element.getAttribute("class").match("arrow-.*-(left|center|right)")[1]
  }

  get arrowSize() {
    return 15
  }

  get elementHeightWithBuffer() {
    return this.elementHeight + 20
  }

  get isAutomatic() {
    return this.automaticValue == true
  }
}
