import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["header", "options", "description"]
  static values = {
    state: String,
    swapIcon: Boolean,
    autoPositioning: Boolean,
    edgeBuffer: Number,
    alignment: String
  }

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === "state") {
        this.element.classList.toggle("dropdown--disabled", value === "disabled")
      }

      if (property === "active") {
        this.isActive ? this.renderer.present() : this.renderer.dismiss()

        this.emitEvent("toggle")
      }

      if (property === "alignment") {
        this.element.classList.remove(...this.alignmentClasses)
        this.element.classList.add(`dropdown--${value}-alignment`)
      }

      if (property === "currentOptionIndex") {
        this.optionElements.forEach((optionElement, index) => {
          const linkElement = optionElement.querySelector(".dropdown-option__link")
          linkElement.classList.toggle("dropdown-option__link--highlighted", index == value)
        })

        this.emitEvent("highlight")
      }

      if (property === "scrollToOptionIndex") {
        const currentElement = this.optionsTarget.querySelector(
          `.dropdown-option[data-index="${this.state.scrollToOptionIndex}"]`
        )
        global.helpers.scrollToOption(currentElement, this.optionsTarget)

        this.emitEvent("highlight")
      }

      if (property === "currentSelection") {
        if (this.state.currentSelection) {
          this.optionElements.forEach(element => {
            element.querySelector(".dropdown-option__link")
              .classList.remove("dropdown-option__link--selected")
          })
          this.state.currentSelection.classList.add("dropdown-option__link--selected")

          if (this.hasDescriptionTarget) {
            this.descriptionTarget.innerText = this.state.currentSelection.innerText.trim()
          }

          if (this.swapIconValue) {
            const currentSelectionIconHTML = this.state.currentSelection.querySelector(".dropdown-option__icon")?.innerHTML
            if (this.iconElement && currentSelectionIconHTML) this.iconElement.innerHTML = currentSelectionIconHTML
          }

          this.emitEvent("change")
        }
      }

      return true
    },

    present: () => {
      // Set an absolute max-height for the optionsTarget.
      const maxHeight = 500

      let maxHeightToSet = maxHeight

      if (this.autoPositioningValue) {
        // Check if we have more space at the top or the bottom.
        // If we have more space at the top, open the dropdown upwards.
        const windowHeight = window.innerHeight
        const middleOfWindow = windowHeight / 2
        const headerTop = this.headerTarget.getBoundingClientRect().top
        const openFromTop = headerTop > middleOfWindow

        if (openFromTop) {
          this.optionsTarget.classList.add("dropdown__options--open-from-top")
        }

        // Prerender the optionsTarget to get the height.
        // This is necessary because the optionsTarget is hidden by default.
        this.optionsTarget.style.display = "block"
        this.optionsTarget.style.visibility = "hidden"

        const optionsTop = this.optionsTarget.getBoundingClientRect().top
        const optionsHeight = this.optionsTarget.offsetHeight
        const optionsBottom = optionsTop + optionsHeight

        this.optionsTarget.style.display = ""
        this.optionsTarget.style.visibility = ""

        // Get the fact that we scrolled past the top or the bottom of the optionsTarget.
        const scrolledPastTopBy = Math.abs(optionsTop)
        const scrolledPastBottomBy = optionsBottom - windowHeight
        const isScrolledPastTop = optionsTop < 0
        const isScrolledPastBottom = optionsBottom > windowHeight

        // Check the available space at the top and bottom, and set the max-height accordingly.
        if (openFromTop && isScrolledPastTop) {
          const currentMaxHeight = optionsHeight - scrolledPastTopBy - this.edgeBufferValue

          if (currentMaxHeight < maxHeight) maxHeightToSet = currentMaxHeight
        }

        if (!openFromTop && isScrolledPastBottom) {
          const currentMaxHeight = optionsHeight - scrolledPastBottomBy - this.edgeBufferValue

          if (currentMaxHeight < maxHeight) maxHeightToSet = currentMaxHeight
        }
      }

      if (this.contentElement) {
        const optionsPaddingTop = parseInt(window.getComputedStyle(this.optionsTarget).paddingTop)
        const optionsPaddingBottom = parseInt(window.getComputedStyle(this.optionsTarget).paddingBottom)
        const optionsPadding = optionsPaddingTop + optionsPaddingBottom
        const maxHeightToSetWithoutPadding = maxHeightToSet - optionsPadding

        this.optionsTarget.style.overflow = "unset"
        this.contentElement.style.maxHeight = `${maxHeightToSetWithoutPadding}px`
        this.contentElement.style.overflowY = "auto"
      }

      this.optionsTarget.style.maxHeight = `${maxHeightToSet}px`
      this.headerTarget.classList.add("dropdown__header--active")
      this.optionsTarget.classList.add("element--on")
    },

    dismiss: () => {
      this.headerTarget.classList.remove("dropdown__header--active")
      this.optionsTarget.classList.remove("element--on")
      this.optionsTarget.classList.remove("dropdown__options--open-from-top")
      this.optionsTarget.style.maxHeight = ""

      if (this.contentElement) {
        this.optionsTarget.style.overflow = ""
        this.contentElement.style.maxHeight = ""
        this.contentElement.style.overflowY = ""
      }
    }
  }

  initialize() {
    this.state = new Proxy({}, this.renderer)
    this.state.state = this.stateValue
    this.state.active = false
    this.state.alignment = this.initialAlignment
    this.state.options = this.optionElements
    this.state.currentSelection = this.initialSelection
    this.indexOptions()
    this.resetCurrentOption()
  }

  indexOptions() {
    this.optionElements.forEach((optionElement, index) => optionElement.dataset.index = index)
  }

  highlight(event) {
    const optionElement = event.currentTarget.closest(".dropdown-option")
    const highlightedIndex = optionElement.dataset.index
    this.state.currentOptionIndex = highlightedIndex
  }

  resetCurrentOption(event) {
    this.state.currentOptionIndex = this.initialIndex
  }

  pick(event) {
    this.state.proposedSelection = event.currentTarget

    if (this.isProposedSelectionDisabled || this.isProposedSelectionInvalid) {
      event.preventDefault()
      return
    }

    if (this.isProposedSelectionDisabled) {
      event.stopImmediatePropagation()
      return
    }

    this.state.currentSelection = this.state.proposedSelection
  }

  toggle(event) {
    event.stopImmediatePropagation()
    event.preventDefault()

    if (this.isDisabled) return

    this.dismissOthers()
    this.resetCurrentOption()

    this.state.active = !this.state.active
  }

  dismiss(event) {
    if (event) {
      const clickedInside = this.optionsTarget.contains(event.target)
      const needsCustomDismissHandler = !this.isWithOptions

      if (clickedInside && needsCustomDismissHandler) return
    }

    this.state.active = false
    this.resetCurrentOption()
  }

  dismissOthers() {
    document.querySelectorAll(".dropdown[data-controller~=\"dropdown\"]").forEach((element) => {
      if (element !== this.element) {
        const dropdown = global.application.getControllerForElementAndIdentifier(element, "dropdown")
        dropdown.state.active = false
      }
    })
  }

  navigateOptions(event) {
    if (!this.isActive || !this.isWithOptions) return

    const currentOption = this.optionsTarget.querySelector(
      `li.dropdown-option[data-index="${this.state.currentOptionIndex}"] .dropdown-option__link`
    )

    const { upKey, downKey, enterKey } = global.helpers.keyCodes

    switch (event.keyCode) {
      case upKey:
        event.preventDefault()

        if (this.state.currentOptionIndex == this.initialIndex) {
          this.state.currentOptionIndex = this.maxIndex
        } else if (this.state.currentOptionIndex > this.minIndex) {
          this.state.currentOptionIndex--
        }
        break
      case downKey:
        event.preventDefault()

        if (this.state.currentOptionIndex < this.maxIndex) {
          this.state.currentOptionIndex++
        }
        break
      case enterKey:
        if (this.state.currentOptionIndex != this.initialIndex) event.preventDefault()
        if (currentOption) {
          currentOption.click()
          return
        }
        break
    }

    this.state.scrollToOptionIndex = this.state.currentOptionIndex
  }

  emitEvent(eventType) {
    const customEvent = new CustomEvent(`dropdown:${eventType}`, {
      bubbles: false,
      detail: this.state
    })
    this.element.dispatchEvent(customEvent)
  }

  set alignment(value) {
    this.state.alignment = value
  }

  get optionElements() {
    return Array.from(this.optionsTarget.querySelectorAll(".dropdown-option"))
  }

  get iconElement() {
    return this.element.querySelector(".dropdown__icon")
  }

  get contentElement() {
    return this.optionsTarget.querySelector(".dropdown__content")
  }

  get isDisabled() {
    return this.state.state === "disabled"
  }

  get isActive() {
    return this.state.active === true
  }

  get alignment() {
    return this.state.alignment
  }

  get isProposedSelectionInvalid() {
    const emptyHrefs = ["#", ""]

    return emptyHrefs.includes(this.state.proposedSelection.getAttribute("href"))
  }

  get isProposedSelectionDisabled() {
    return this.state.proposedSelection.classList.contains("dropdown-option__link--disabled")
  }

  get initialSelection() {
    return this.optionsTarget.querySelector(".dropdown-option__link--selected")
  }

  get initialIndex() {
    return -1
  }

  get minIndex() {
    return 0
  }

  get maxIndex() {
    return this.state.options.length - 1
  }

  get isWithOptions() {
    return this.optionElements.length > 0
  }

  get initialAlignment() {
    return this.alignmentValue || "left"
  }

  get alignmentClasses() {
    return ["dropdown--left-alignment", "dropdown--center-alignment", "dropdown--right-alignment"]
  }
}
