import Event from "./event"

/**
 * Optimises the layout of the events provided.
 */
class EventArrangement {
  /**
  * @param {Object} details
  * @param {Event[]} details.events the events to optimise layout for. They are
  * provided sorted by startTime + duration.
  */
  constructor({ events }) {
    // We create an internal copy because we're going to mutate the events in
    // this list.
    this.events = events.map(event => new Event(event))
  }

  /**
   * @returns {Event[]} the returned events are sorted left to right if their
   *  start times are the same.
   */
  arrangedEvents() {
    const eventGroups = this.calculateEventGroups()

    this.updateEventsPosition(eventGroups)

    return this.events
  }

  /**
   * @returns {EventGroup[]}
   */
  calculateEventGroups() {
    // To calculate the overlapping events, we have to think of how to place
    // all the events. The way we've come up with is to put the events in a
    // bunch of groups. A group contains all the events which share horizontal
    // space. An event can be in multiple groups, but its width will be the
    // minimum allowed based on all the groups it is a part of.
    // Each group is the longest time frame where a bunch of events share
    // horizontal space.

    /**
     * Will always be sorted by startTime
     * @type {EventGroup[]}
     */
    let groups = []

    this.events.forEach(event => groups = this.mergeEventIntoGroups(event, groups))

    return groups
  }

  /**
   * @param {EventGroup[]} groups
   * @returns {void}
   */
  updateEventsPosition(groups) {
    // The way we want to approach it is a simplified and non-optimal approach.
    // The optimal approach would be some form of DP where we basically try all
    // the possible combinations and select the best one. The criteria for the
    // best one would be the one which maximises the mean of the area of the
    // events.
    // We will start from top to bottom and try to adjust events in each group.
    // Now, we may have some events from earlier groups which overlap in this
    // group. In this case, we would assume the space taken by these events as
    // consumed and try to fit in the remaining events in the gaps left.

    // We would first calculate the max overlaps of each event. We would use this
    // to calculate the max width allowed(even though in the optimal solution
    // the max width could be greater in some cases).
    this.updateEventsOverlaps(groups)

    this.fillEventsInGaps(groups)

    this.maximiseEventWidth(groups)
  }

  /**
   * merge the given event into the provided list of event groups for the day.
   * @param {Event} event
   * @param {EventGroup[]} groups
   * @returns {EventGroup[]}
   */
  mergeEventIntoGroups(event, groups) {
    // We need a list because if we move parts of the time frame of an event
    // to a group, that does not mean the entire event is covered by that group.
    // e.g. if the group is for 9 AM to 9:30 AM, and the event is from 8:45 AM
    // to 9:45 AM, then there's parts of the event which are not covered by the
    // group and need to be put in other groups.
    let eventGroups = [new EventGroup({ startTime: event.startTimeToInteger, endTime: event.endTimeToInteger, events: [event] })]
    /** @type {EventGroup[]} */
    const newGroups = []
    for (const group of groups) {
      // The following if check will `continue` if there is no overlap with the group
      if (event.startTimeToInteger >= group.endTime || event.endTimeToInteger <= group.startTime) {
        newGroups.push(group)
        continue
      }

      // There is definitely overlap with this group
      /** @type {EventGroup[]} */
      const newEventGroups = []
      for (const eventGroup of eventGroups) {
        // If no overlap for this , then just move it to be tested against the
        // next group.
        if (eventGroup.startTime >= group.endTime || eventGroup.endTime <= group.startTime) {
          newEventGroups.push(eventGroup)
          continue
        }

        // First we handle the non-overlapping parts at the top.
        // Only one of these 2 is possible.
        if (group.startTime < eventGroup.startTime) {
          const groupPrev = new EventGroup({ startTime: group.startTime, endTime: eventGroup.startTime, events: [...group.events] })
          newGroups.push(groupPrev)
        }
        if (eventGroup.startTime < group.startTime) {
          const eventGroupPrev = new EventGroup({ startTime: eventGroup.startTime, endTime: group.startTime, events: [event] })
          newEventGroups.push(eventGroupPrev)
        }

        // Then we add the overlapping group
        const overlapStartTime = Math.max(eventGroup.startTime, group.startTime)
        const overlapEndTime = Math.min(eventGroup.endTime, group.endTime)
        const overlapGroup = new EventGroup({ startTime: overlapStartTime, endTime: overlapEndTime, events: [...group.events, event] })
        newGroups.push(overlapGroup)

        // Then we handle the non-overlapping parts at the bottom
        // Only one of these 2 is possible.
        if (eventGroup.endTime < group.endTime) {
          const groupNext = new EventGroup({ startTime: eventGroup.endTime, endTime: group.endTime, events: [...group.events] })
          newGroups.push(groupNext)
        }
        if (group.endTime < eventGroup.endTime) {
          const eventGroupNext = new EventGroup({ startTime: group.endTime, endTime: eventGroup.endTime, events: [event] })
          newEventGroups.push(eventGroupNext)
        }
      }
      eventGroups = newEventGroups
    }

    newGroups.push(...eventGroups)
    // Because of how we've written the sequence of operations, newGroups is
    // already sorted.
    return newGroups
  }

  /**
   * These overlaps will help us figure out the max width.
   * @param {EventGroup[]} groups
   * @returns {void}
   */
  updateEventsOverlaps(groups) {
    for (const group of groups) {
      for (const event of group.events) {
        event.maxOverlaps = Math.max(event.maxOverlaps, group.events.length)
      }
    }
  }

  /**
   * @param {EventGroup[]} groups
   * @returns {void}
   */
  fillEventsInGaps(groups) {
    // Now, we go through each group. Find the gaps which we can fill with the
    // events and start filling.
    // NOTE: In super rare, although theoretically possible, edge case we might
    // have a few very small gaps which could be clubbed together(only in some
    // instances) to increase the available gap for an event.
    // Dealing with them will be non-trivial so I believe we should deal with it
    // only if the need arises(There would need to be a lot of events for the
    // same time frame and it would probably make more sense to use more
    // detailed views in those cases anyways).
    for (const group of groups) {
      const gaps = this.gapsForEvents(group)
      for (const event of group.events) {
        // It is one of the events with overlaps and we've already placed it in
        // a previous group.
        if (event.left !== undefined) {
          continue
        }

        const gap = gaps.pop()
        const desiredWidth = (100 / event.maxOverlaps)

        event.left = gap.start
        if (gap.width <= desiredWidth) {
          event.width = gap.width
        } else {
          event.width = desiredWidth
          gap.start += desiredWidth
          gaps.push(gap)
          // We could potentially make do without this sorting and just find the
          // largest gap and manage an array with empty indices, but the list of
          // gaps will be too small to worry about.
          this.sortGaps(gaps)
        }
      }
    }
  }

  /**
   * We'll go through every event and if there is space to their right then
   * we'll expand the event width.
   * @param {EventGroup[]} groups
   * @returns {void}
   */
  maximiseEventWidth(groups) {
    // Sort events by their left value. Required because while filling in gaps
    // we might've moved the events around.
    for (const group of groups) {
      group.events.sort((a, b) => a.left - b.left)
    }

    // The basic idea is very simple, for each event we'll go through all the
    // groups that it is a part of and try to find the min space to their right
    // that we can find, as that is how much we can expand the event by without
    // causing an overlap. If there is some, we increase the width of the event
    // to fill it.
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i]

      for (let j = 0; j < group.events.length; j++) {
        const event = group.events[j]
        // We've already handled it in a previous group.
        if (event.startTimeToInteger < group.startTime) {
          continue
        }

        const eventRight = event.left + event.width

        let nextGroup, nextEvent, nextLeft
        let minRight = Infinity
        let nextGroupIndex = i
        while (minRight > eventRight && nextGroupIndex < groups.length) {
          nextGroup = groups[nextGroupIndex]
          const eventIndex = nextGroup.events.indexOf(event)
          if (eventIndex === -1) {
            // The event ended on the previous group
            break
          }

          nextEvent = nextGroup.events[eventIndex + 1]
          nextLeft = nextEvent ? nextEvent.left : 100
          minRight = Math.min(minRight, nextLeft)

          nextGroupIndex += 1
        }

        if (minRight !== Infinity && minRight > eventRight) {
          event.width = minRight - event.left
        }
      }
    }
  }

  /**
   * The returned gaps are sorted by shortest gap first
   * @param {EventGroup} group
   * @returns {Gap[]}
   */
  gapsForEvents(group) {
    const gaps = []

    // We have sorted it such that
    // All the events with `left` set, are sorted left to right
    // Then all the events without it are sorted based on their duration, longest first
    group.events.sort((a, b) => {
      if (a.left === undefined && b.left === undefined) {
        return b.endTimeToInteger - a.endTimeToInteger
      } else {
        // This way we push the events without `left` to the end
        return (a.left === undefined ? 100 : a.left) - (b.left === undefined ? 100 : b.left)
      }
    })

    let prev = 0
    for (const event of group.events) {
      if (event.left === undefined) {
        break
      }

      if (prev < event.left) {
        gaps.push(new Gap({ start: prev, end: event.left }))
      }

      prev = event.left + event.width
    }

    if (prev < 100) {
      gaps.push(new Gap({ start: prev, end: 100 }))
    }

    return this.sortGaps(gaps)
  }

  /**
   * sorts the gaps by the shortest gap first
   * @param {Gap[]} gaps
   * @returns {Gap[]}
   */
  sortGaps(gaps) {
    return gaps.sort((a, b) => a.width - b.width)
  }
}

/** ------------------------- Helper classes start ------------------------- */
class EventGroup {
  /**
  * @param {Object} details
  * @param {Number} details.startTime
  * @param {Number} details.endTime
  * @param {Event[]} details.events
  */
  constructor({ startTime, endTime, events }) {
    this.startTime = startTime
    this.endTime = endTime
    this.events = events
  }
}

class Gap {
  constructor({ start, end }) {
    this.start = start
    this.end = end
  }

  get width() {
    return this.end - this.start
  }
}
/** ------------------------- Helper classes end ------------------------- */

export default EventArrangement
