import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.prepare()
  }

  disconnect() {
    this.destroyWidget()
  }

  prepare() {
    this.loadWidget(this.scriptUrl)
      .then(() => { this.initWidget() })
      .catch((error) => { console.error("Trustpilot load failed:", error) })
  }

  loadWidget(url) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script")
      script.src = url
      script.async = true
      script.onload = resolve
      script.onerror = reject
      document.head.appendChild(script)
    })
  }

  initWidget() {
    if (global.Trustpilot && typeof global.Trustpilot.loadFromElement === "function") {
      global.Trustpilot.loadFromElement(this.element)
    } else {
      console.error("Trustpilot init failed")
    }
  }

  destroyWidget() {
    this.element.innerHTML = ""
  }

  get scriptUrl() {
    return "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
  }
}
