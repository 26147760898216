import GlobalRegistration from "../global_registration"

class NodeManager {
  constructor(name) {
    this.name = name
    this.root = null
    this.currentNode = null
  }

  push(item, notify = false) {
    const node = {
      item,
      parent: this.currentNode
    }

    this._updateCurrentNode(node)
  }

  pop(notify = true) {
    if (!this.currentNode) return null

    const node = this.currentNode
    this.currentNode = node.parent // Move up to the parent node

    if (!node.parent) {
      // If there's no parent, it means this is the root node
      // and then we can clear the root
      this.root = null
    }

    this._cleanUp()
    if (notify) {
      this._notify("pop", node)
      this._emitChildRemoval(node, node.parent)
    }
    return node
  }

  clear() {
    let poppedNode = null
    while (this.currentNode) {
      poppedNode = this.pop()
    }

    return poppedNode
  }

  clearUpTo(node) {
    if (!node) return

    let poppedNode = null
    while (this.currentNode && this.currentNode !== node) {
      poppedNode = this.pop()
    }

    return poppedNode
  }

  closestOriginNode() {
    let currentNode = this.currentNode
    // We need to traverse up to the root node to find the origin node
    while (currentNode.item.dataset.sheetStackTarget !== "origin" && currentNode.parent) {
      currentNode = currentNode.parent
    }

    return currentNode
  }

  length() {
    let currentNode = this.currentNode
    let count = 0
    while (currentNode) {
      count++
      currentNode = currentNode.parent
    }

    return count
  }

  _notify(action, node = this.currentNode) {
    if (node) {
      this._emitEvent(action, node)
    }
  }

  _updateCurrentNode(node) {
    if (!this.currentNode) {
      this.root = node
    }

    this.currentNode = node
  }

  _emitEvent(action, node) {
    const eventName = `sheet-update:${action}`
    const nodeElement = node.item
    const customEvent = new CustomEvent(eventName, {
      bubbles: false,
      detail: { item: nodeElement }
    })

    if (nodeElement instanceof HTMLElement) {
      nodeElement.dispatchEvent(customEvent)
    }
  }

  _emitChildRemoval(currentNode, parentNode) {
    if (!parentNode) return

    const parentElement = parentNode.item

    const childRemovalEvent = new CustomEvent("sheet-update:child-removal", {
      bubbles: false, // No bubbling because we aim only the parent element
      detail: { node: currentNode }
    })

    parentElement.dispatchEvent(childRemovalEvent)

    // We need to notify the root node as well if it's not the parent node
    if (this.root && this.root !== parentNode) {
      this._emitChildRemoval(currentNode, this.root)
    }
  }

  _cleanUp() {
    if (!this.root) {
      const globalRegistration = new GlobalRegistration(this.name)
      globalRegistration.clear()
    }
  }
}

export default NodeManager
