class Event {
  #MINUTES_TO_PERCENTAGE_BASE_SCALE = 100.0 / 1440.0

  constructor({ startTime, endTime, referenceId, paymentToken, title, description, url, dataset, style, ...attributes }) {
    this.startTime = startTime
    this.endTime = endTime
    this.referenceId = referenceId
    this.paymentToken = paymentToken
    this.title = title
    this.description = description
    this.maxOverlaps = 1
    this.url = url
    this.dataset = { url, referenceId, paymentToken, title, description, startTime, endTime, ...dataset }
    this.style = style
    this.width = undefined
    this.left = undefined

    Object.assign(this, attributes)
  }

  get startTimeMoment() {
    return global.moment(this.startTime)
  }

  get endTimeMoment() {
    return global.moment(this.endTime)
  }

  get startTimeToInteger() {
    return this.startTimeMoment.valueOf()
  }

  get endTimeToInteger() {
    return this.endTimeMoment.valueOf()
  }

  get duration() {
    return (this.endTimeMoment - this.startTimeMoment) / 1000 / 60
  }

  get durationToPercentage() {
    return this.duration * this.#MINUTES_TO_PERCENTAGE_BASE_SCALE
  }

  get minutesSinceMidnight() {
    return (this.startTimeMoment.hour() * 60) + this.startTimeMoment.minute()
  }

  get minutesSinceMidnightToPercentage() {
    return this.minutesSinceMidnight * this.#MINUTES_TO_PERCENTAGE_BASE_SCALE
  }

  get top() {
    return this.minutesSinceMidnightToPercentage
  }

  get height() {
    return this.durationToPercentage
  }
}

export default Event
