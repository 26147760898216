export default {
  up: (element, duration = 150, callback) => {
    element.style.transitionProperty = "height, margin, padding, opacity"
    element.style.transitionDuration = `${duration}ms`
    element.style.transitionTimingFunction = "ease-in-out"
    element.style.boxSizing = "border-box"
    element.style.height = `${element.offsetHeight}px`
    element.offsetHeight
    element.style.height = 0
    element.style.opacity = 0
    element.style.paddingTop = 0
    element.style.paddingBottom = 0
    element.style.marginTop = 0
    element.style.marginBottom = 0

    window.setTimeout(() => {
      element.style.display = "none"
      element.style.removeProperty("height")
      element.style.removeProperty("opacity")
      element.style.removeProperty("padding-top")
      element.style.removeProperty("padding-bottom")
      element.style.removeProperty("margin-top")
      element.style.removeProperty("margin-bottom")
      element.style.removeProperty("overflow")
      element.style.removeProperty("transition-duration")
      element.style.removeProperty("transition-property")
      element.style.removeProperty("transition-timing-function")

      if (callback) {
        callback()
      }
    }, duration)
  },

  down: (element, duration = 150, callback) => {
    element.style.removeProperty("display")

    let display = window.getComputedStyle(element).display
    if (display === "none") {
      display = "block"
    }

    element.style.display = display
    const height = element.offsetHeight
    element.style.height = 0
    element.style.opacity = 0
    element.style.paddingTop = 0
    element.style.paddingBottom = 0
    element.style.marginTop = 0
    element.style.marginBottom = 0
    element.offsetHeight
    element.style.boxSizing = "border-box"
    element.style.transitionProperty = "height, margin, padding, opacity"
    element.style.transitionDuration = `${duration}ms`
    element.style.transitionTimingFunction = "ease-in-out"
    element.style.height = `${height}px`
    element.style.opacity = 1
    element.style.removeProperty("padding-top")
    element.style.removeProperty("padding-bottom")
    element.style.removeProperty("margin-top")
    element.style.removeProperty("margin-bottom")

    window.setTimeout(() => {
      element.style.removeProperty("height")
      element.style.removeProperty("opacity")
      element.style.removeProperty("overflow")
      element.style.removeProperty("transition-duration")
      element.style.removeProperty("transition-property")
      element.style.removeProperty("transition-timing-function")

      if (callback) {
        callback()
      }
    }, duration)
  },

  toggle: (element, duration = 150) => {
    if (window.getComputedStyle(element).display === "none") {
      return global.helpers.slide.down(element, duration)
    } else {
      return global.helpers.slide.up(element, duration)
    }
  }
}
