import StackManager from "models/stack_manager"

// @options
// - reloadPrimary: boolean (default: false). If true, it will trigger a reload event on that is handled by the primary sheet element
// - notifyCallbacks: boolean (default: true). If false, it will not trigger the removed event on the last element in the stack
// - keepPrimary: boolean (default: false). If true, it will keep the primary element in the stack. This conflicts with reloadPrimary, overriding it to false
export default function(stackName, {
  reloadPrimary = false,
  notifyCallbacks = true,
  keepPrimary = false,
  ...data
  } = {}) {
  reloadPrimary = reloadPrimary && !keepPrimary
  const stackManager = StackManager.loadFor(stackName)
  stackManager.clear({ reloadPrimary, notifyCallbacks, keepPrimary, ...data })
}
