import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    count: Number,

    linkSample: String,
    optOutString: String,

    frenchMarketing: Boolean
  }

  static targets = [
    "extraCharactersCheckbox",
    "textarea",
    "price"
  ]

  connect() {
    this.initValues()
    this.calculateCharacters()
  }

  initValues() {
    this.possibleWarnings = [
      "multiple-messages-error",
      "weird-chars-error"
    ]

    this.currentWarnings = []
    this.messagesCount = 1

    this.frenchMessage = "STOP au 36179"
  }

  calculateCharacters() {
    const text = this.finalText

    if (text.length <= this.threshold) {
      this.currentWarnings = this.currentWarnings.filter(item => item !== "multiple-messages-error")
      this.messagesCount = 1
    } else {
      this.messagesCount = Math.ceil(text.length / this.thresholdIfMultipleSMS)
      if (!this.currentWarnings.includes("multiple-messages-error")) { this.currentWarnings.push("multiple-messages-error") }
    }

    if (this.anyNonGsmChars(this.finalText) == true) {
      if (!this.currentWarnings.includes("weird-chars-error")) { this.currentWarnings.push("weird-chars-error") }
    } else {
      this.currentWarnings = this.currentWarnings.filter(item => item !== "weird-chars-error")
    }

    this.processWarnings()
  }

  processWarnings() {
    this.possibleWarnings.forEach((warning) => {
      const el = document.getElementById(warning)

      if (!el) { return }

      if (this.currentWarnings.includes(warning)) {
        el.classList.remove("hidden")
      } else {
        el.classList.add("hidden")
      }
    })

    this.displayMessageCount()
  }

  displayMessageCount() {
    const messagesCountSelector = document.getElementById("multiple-messages-error").querySelector(".notification-banner__body")
    const mask = document.getElementById("multiple-messages-error").dataset.warningText

    messagesCountSelector.innerHTML = mask.replace("%{count}", this.messagesCount)
    this.displaySmsPrice()
  }

  displaySmsPrice() {
    if (!this.hasPriceTarget) { return }

    const smsPrice = this.messagesCount * this.priceTarget.dataset.price
    const formatCurrency = Intl.NumberFormat(this.priceTarget.dataset.locale, {
        style: "currency",
        currency: this.priceTarget.dataset.currency,
        currencyDisplay: "narrowSymbol",
    })
    this.priceTarget.querySelector("#cancellation-sms-charge").textContent = `${formatCurrency.format(smsPrice)}`
  }

  /* eslint-disable */
  anyNonGsmChars(str) {
    const regexp = new RegExp("([^A-Za-z0-9 \r\n@£\$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()\*\+,\\\-\.\/\:;\<\=\>\?¡ÄÖÑÜ§¿äöñüà^{}\[~\]|\u20AC]*)")

    return regexp.test(str)
  }
  /* eslint-enable */

  get finalText() {
    let text = this.textareaTarget.value

    const extraSettings = this.hasExtraCharactersCheckboxTarget && this.hasLinkSampleValue && this.hasOptOutStringValue
    if (!extraSettings) { return text }

    if (this.extraCharactersCheckboxTarget.checked == true) {
      text = text + " " + this.linkSampleValue
    } else {
      text = text + "\n\n" + this.optOutStringValue + ": " + this.linkSampleValue
    }

    if (this.frenchMarketingValue == true) {
      text = text + " " + this.frenchMessage
    }

    return text
  }

  get threshold() {
    return (this.anyNonGsmChars(this.finalText) == true) ? this.nonGsmThreshold : this.gsmThreshold
  }

  get thresholdIfMultipleSMS() {
    return (this.anyNonGsmChars(this.finalText) == true) ? this.nonGsmThresholdIfMultipleSMS : this.gsmThresholdIfMultipleSMS
  }

  get gsmThreshold() { return 160 }

  get gsmThresholdIfMultipleSMS() { return 153 }

  get nonGsmThreshold() { return 70 }

  get nonGsmThresholdIfMultipleSMS() { return 67 }
}
