import { Controller } from "@hotwired/stimulus"

// Specifically required to be able to resize the text on the curve
export default class extends Controller {
  static targets = ["text", "curved"]

  connect() {
    this.textFitEventHandler = () => {
      this.curvedTarget.style.fontSize = this.fontSize
    }

    this.textTarget.addEventListener("text-fit", this.textFitEventHandler)
  }

  disconnect() {
    if (this.hasTextTarget) {
      this.textTarget.removeEventListener(this.textFitEventHandler)
    }
  }

  get fontSize() {
    if (this.textTarget.childElementCount === 1) {
      return this.textTarget.firstChild.style.fontSize
    } else {
      return "22px"
    }
  }
}
