import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // We have to manually manage the event listener because setting
    // data-action="usetiful-tour:visited@window->usetiful-tour#markAsVisited"
    // breaks the layout at the -> character.
    // TODO: Investigate why the data-action is not working in the layout.
    this.markAsVisitedBind = this.markAsVisited.bind(this)

    window.addEventListener("usetiful-tour:visited", this.markAsVisitedBind)
  }

  disconnect() {
    window.removeEventListener("usetiful-tour:visited", this.markAsVisitedBind)
  }

  markAsVisited(event) {
    const tourName = event.detail.name
    const onSuccess = event.detail.onSuccess
    const method = "POST"
    const params = new URLSearchParams()
    params.append("visited_tour[tour_name]", tourName)

    Rails.ajax({
      type: method,
      dataType: "json",
      url: "/admin/usetiful/visited_tours",
      data: params.toString(),
      success: onSuccess
    })
  }
}
