import DataSource from "./data_source"

class Renderer {
  constructor(data, options = {}) {
    this.eventClass = options.eventClass || "event"
    this.dataSource = new DataSource(data)
  }

  render(callback) {
    return this.dataSource.events.map(event => {
      const element = this.renderEvent(event)

      return callback ? callback(element, event) : element
    })
  }

  renderEvent(event) {
    const element = document.createElement("a")

    element.classList.add(this.eventClass)
    event?.style?.classes?.forEach(variant => element.classList.add(variant))

    element.setAttribute("href", event.url)

    // We have to manually assign each dataset key on the element because it's a read only property.
    Object.keys(event.dataset).forEach(key => element.dataset[key] = event.dataset[key])

    element.style.width = `${event.width}%`
    element.style.height = `${event.height}%`
    element.style.top = `${event.top}%`
    element.style.left = `${event.left}%`

    if (event.title) {
      const titleElement = document.createElement("span")
      titleElement.classList.add(`${this.eventClass}-title`)
      titleElement.innerHTML = event.title

      element.appendChild(titleElement)
    }

    if (event.description) {
      const descriptionElement = document.createElement("span")
      descriptionElement.classList.add(`${this.eventClass}-description`)
      descriptionElement.innerText = event.description

      element.appendChild(descriptionElement)
    }

    return element
  }
}

export default Renderer
