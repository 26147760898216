import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    selector: String
  }

  scrollIntoView(_event) {
    console.log(this.selectorValue)
    console.log(this.targetElement)
    if (!this.targetElement) { return }

    console.log(this.targetElement)

    this.targetElement.scrollIntoView()
  }

  get targetElement() {
    return this.element.querySelector(this.selectorValue)
  }
}
