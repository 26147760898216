import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    five: Boolean,
  }

  static targets = [
    "seconds"
  ]

  secondsTotal() {
    return 5
  }

  connect() {
    if (this.fiveValue != true) return
    if (this.urlValue == "") return

    let sec = this.secondsTotal()

    this.counter = setInterval(() => {
      sec = sec - 1

      if (sec <= 0) {
        clearInterval(this.counter)
        this.redirect(this.urlValue)
        return
      }

      this.secondsTarget.textContent = sec
    }, 1000)
  }

  disconnect() {
    clearTimeout(this.counter)
  }

  redirect(url) {
    window.location.href = url
  }
}
