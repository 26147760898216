export default function(base, currency, locale) {
  const formatCurrency = Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    currencyDisplay: "narrowSymbol",
  })

  const currencySymbol = formatCurrency.formatToParts(1).find((part) => part.type === "currency").value

  if (currencySymbol == "$") {
    return `${formatCurrency.format(base)} ${currency}`
  } else {
    return `${formatCurrency.format(base)}`
  }
}
