import GlobalRegistration from "./global_registration"
import SheetsTree from "./sheets/node_manager"

class StackManager {
  constructor(name, sheetsTree = null) {
    this.name = name
    this.sheets = sheetsTree || new SheetsTree(name)
  }

  // Load a stack manager for a given name, otherwise create a new one
  static loadFor(name) {
    const stackRegistration = new GlobalRegistration(name)
    let _stackManager = stackRegistration.get()
    if (!_stackManager) {
      _stackManager = new StackManager(name)
      stackRegistration.set(_stackManager)
    }

    return _stackManager
  }

  push(item, ...args) {
    this.sheets.push(item, ...args)
    this._persistStack()
  }

  pop({ notify = true } = {}) {
    const poppedNode = this.sheets.pop(notify)

    this._emitGenericEvent("pop", poppedNode)
    this._persistStack()
    return poppedNode ? poppedNode.item : null
  }

  // This clears the stack
  // if reloadPrimary is true, it will trigger a reload event on the primary sheet element
  // if notifyCallbacks is true, it will trigger the removed event on the last element in the stack
  // otherwise, it will trigger a pop event on the last element in the stack
  clear({ reloadPrimary = false, notifyCallbacks = true, keepPrimary = false, ...data } = {}) {
    this._reloading = reloadPrimary && !keepPrimary
    const lastRemovedNode = this._removeNodes(keepPrimary)
    if (!lastRemovedNode) return null

    if (reloadPrimary && !keepPrimary) {
      this._emitElementEvent("reload", lastRemovedNode, data)
    } else {
      this._emitGenericEvent("pop", lastRemovedNode)
    }

    // If we don't want to notify the callbacks to react to the removal of the last element
    if (notifyCallbacks) {
      this._emitElementEvent("removed", lastRemovedNode)
    }

    this._persistStack()
    return lastRemovedNode ? lastRemovedNode.item : null
  }

  closestOrigin() {
    return this.sheets.closestOriginNode().item
  }

  hasNodes() {
    return this.sheets.root !== null
  }

  // We need a flag to indicate that the stack is finished reloading
  finishReloading() {
    this._reloading = false
  }

  _removeNodes(keepPrimary = false) {
    if (keepPrimary) {
      return this.sheets.clearUpTo(this.sheets.root)
    } else {
      return this.sheets.clear()
    }
  }

  _emitElementEvent(action, node, data) {
    if (!node || !node.item) return
    node.data = data

    const customEvent = new CustomEvent(this._eventNameFor(action), {
      bubbles: false,
      detail: { node }
    })

    node.item.dispatchEvent(customEvent)
  }

  // This allows any independent component to act upon an action made in the stack
  _emitGenericEvent(action, node) {
    if (!node) return

    const customEvent = new CustomEvent(this._eventNameFor(action), {
      bubbles: false,
      detail: { node }
    })

    window.dispatchEvent(customEvent)
  }

  _persistStack() {
    const stackRegistration = new GlobalRegistration(this.name)
    stackRegistration.set(this)
  }

  _eventNameFor(action) {
    return `stack-${this.name}:${action}`
  }

  get popEventName() {
    return `stack-${this.name}:pop`
  }

  get reloadEventName() {
    return `stack-${this.name}:reload`
  }

  get length() {
    return this.sheets.length()
  }

  get isReloading() {
    return this._reloading
  }
}

export default StackManager
