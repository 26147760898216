export default function tagReplacements(template, replacements, options = {}) {
  const mustacheRegex = /{{\s*(\w+)\s*}}/g
  const clearUnknownTags = options.clearUnknownTags || false
  const sanitizedTemplate = template.replace(mustacheRegex, function(match, key) {
    return replacements[key] || `{{${key}}}`
  })

  if (clearUnknownTags) {
    return sanitizedTemplate.replace(mustacheRegex, "")
  }

  return sanitizedTemplate
}
