import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    eventName: String,
    properties: Object,
    sendEventOnConnect: { type: Boolean, default: false }
  }

  connect() {
    if (this.sendEventOnConnectValue) this.captureEvent()
  }

  captureEvent() {
    if (!window.posthog) return

    window.posthog.capture(this.eventNameValue, this.propertiesValue || {})
  }
}
