import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "source",
    "linkInputButton"
  ]

  static values = {
    copyStr: String,
    copiedStr: String,
    primaryClass: String,
    secondaryClass: String
  }

  copy(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.sourceTarget.value || this.sourceTarget.innerText)
    this.animateButton()
  }

  animateButton() {
    const secondaryClass = this.secondaryClassValue || "button--secondary"
    const primaryClass = this.primaryClassValue || "button--primary"

    this.linkInputButtonTarget.innerHTML = this.copiedStrValue
    this.linkInputButtonTarget.classList.remove(primaryClass)
    this.linkInputButtonTarget.classList.add(secondaryClass)

    const timer = () => {
      this.linkInputButtonTarget.innerHTML = this.copyStrValue
      this.linkInputButtonTarget.classList.remove(secondaryClass)
      this.linkInputButtonTarget.classList.add(primaryClass)
      clearInterval(countdownTimer)
    }

    var countdownTimer = setInterval(timer, 1000)
  }
}
