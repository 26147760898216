import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.toggle()
  }

  toggle() {
    if (!this.inputTargets) { return }

    this.inputTargets.forEach(el => {
      const toggleTargets = this.toggleTargetsFor(el)
      if (el.checked) {
        toggleTargets.forEach(tgt => tgt.classList.add("element--on"))
      } else {
        toggleTargets.forEach(tgt => tgt.classList.remove("element--on"))
      }
    })
  }

  toggleTargetsFor(el) {
    return document.querySelectorAll(el.dataset.toggleTargets)
  }
}
