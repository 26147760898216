function camelize(string) {
  return string.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace("-", "")
      .replace("_", "")
  })
}

const helpers = require.context(".", true, /_helper\.js$/)
global.helpers = {}
helpers.keys().forEach((key) => {
  var functionName = camelize(key.replace("./", "").replace("_helper.js", ""))
  global.helpers[functionName] = helpers(key).default
})
