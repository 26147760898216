import Event from "./event"
import EventArrangement from "./event_arrangement"

class DataSource {
  constructor(data) {
    this.events = this.prepareEvents(data)
  }

  prepareEvents(data) {
    let events = []

    events = this.initializeEvents(data)
    events = new EventArrangement({ events }).arrangedEvents()

    return events
  }

  initializeEvents(data) {
    // These events come grouped by barber from the backend. So, they need to
    // be sorted again after merging all events from all barbers.
    return data.map(item => new Event(item)).sort((a, b) => {
      let result = a.startTimeToInteger - b.startTimeToInteger
      // If start time is the same, then sort by duration, longest first.
      if (result === 0) {
        result = b.endTimeToInteger - a.endTimeToInteger
      }

      return result
    })
  }
}

export default DataSource
