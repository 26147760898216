class GlobalRegistration {
  constructor(registryName) {
    window.registry = window.registry || {}
    this._registryName = registryName
  }

  get() {
    return window.registry[this._registryName]
  }

  set(value) {
    window.registry[this._registryName] = value
  }

  clear() {
    window.registry[this._registryName] = null
  }
}

export default GlobalRegistration
