import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    selector: String
  }

  present(event) {
    event.preventDefault()

    // We set it here because the presentation controller could call its connect before the
    // dialog controller is created.
    // Here checking on `undefined` is equivalend to `if defined?(@variable)` check in ruby as
    // undefined is returned when a field/variable is not set.
    if (this.dialogController === undefined) this.setDialogController()

    if (this.dialogController) this.dialogController.open()
  }

  setDialogController() {
    const dialog = this.hasSelectorValue ? this.dialogElement = document.querySelector(this.selectorValue) : null
    if (dialog) {
      this.dialogController = global.application.getControllerForElementAndIdentifier(
        dialog, "dialog"
      )
    }

    if (!this.dialogController) this.dialogController = null
  }
}
