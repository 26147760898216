import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    selector: String
  }

  connect() {
    if (this.hasSelectorValue) this.actionSheetElement = document.querySelector(this.selectorValue)
  }

  present(event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.actionSheetElement) {
      const actionSheetController =
        global.application.getControllerForElementAndIdentifier(this.actionSheetElement, "action-sheet")

      actionSheetController.open()
    }
  }
}
