import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["validationBannerTemplate", "validationBanners"]

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === "errors") {
        this.renderer.removeExistingValidationMessages()

        if (this.state.errors) {
          for (const [field, validationMessages] of Object.entries(this.state.errors)) {
            if (field === "base") {
              this.renderer.renderValidationBannerMessages(field, validationMessages)
            } else {
              this.renderer.renderFormFieldValidationMessages(field, validationMessages)
            }
          }
        }
      }

      return true
    },

    removeExistingValidationMessages: () => {
      this.validationBannersTarget.innerHTML = ""

      this.element.querySelectorAll(".form-field__validation-messages").forEach((validationMessagesNode) => {
        validationMessagesNode.innerHTML = ""
      })

      this.element.querySelectorAll(".form-field").forEach((formFieldNode) => {
        formFieldNode.classList.remove("form-field--error")
      })
    },

    renderFormFieldValidationMessages: (field, validationMessages) => {
      const fieldNode = this.element.querySelector(`[name*="[${field}]"]:not([type="hidden"])`)

      const formFieldNode = fieldNode.closest(".form-field")
      const validationMessageTemplateNode = formFieldNode.querySelector("template.form-field__validation-message-template")
      const validationMessagesNode = formFieldNode.querySelector(".form-field__validation-messages")

      validationMessages.forEach((validationMessage) => {
        const validationMessageNode = validationMessageTemplateNode.content.cloneNode(true)
        const validationMessageTextNode = validationMessageNode.querySelector(".form-field__validation-message-text")
        validationMessageTextNode.innerText = validationMessage
        validationMessagesNode.appendChild(validationMessageNode)
      })

      formFieldNode.classList.add("form-field--error")

    },

    renderValidationBannerMessages: (field, validationMessages) => {
      validationMessages.forEach((validationMessage) => {
        const validationBannerNode = this.validationBannerTemplateTarget.content.cloneNode(true)
        const validationBannerBodyNode = validationBannerNode.querySelector(".notification-banner__body")
        validationBannerBodyNode.innerText = validationMessage
        this.validationBannersTarget.appendChild(validationBannerNode)
      })
    }
  }

  initialize() {
    this.state = new Proxy({}, this.renderer)

    this.clearErrors()
  }

  submit(event) {
    const [data, , ] = event.detail

    this.state.errors = data["errors"] || []
  }

  clearErrors(event) {
    this.state.errors = []
  }
}
