export default function(optionElement, optionsElement) {
  const optionsHeight = parseInt(getComputedStyle(optionsElement).height)
  const optionHeight = [
    parseInt(getComputedStyle(optionElement).height),
    parseInt(getComputedStyle(optionElement).marginTop),
    parseInt(getComputedStyle(optionElement).marginBottom)
  ].reduce((a, b) => a + b)
  const optionsScrollTop = optionsElement.scrollTop
  const topDistance = optionElement.offsetTop - optionsScrollTop
  const bottomDistance = optionsHeight - topDistance - optionHeight

  if (topDistance < 0) {
    optionsElement.scrollTop = optionsElement.scrollTop + topDistance
  }

  if (bottomDistance < 0) {
    optionsElement.scrollTop = optionsElement.scrollTop - bottomDistance
  }
}
