import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  visit() {
    Turbolinks.visit(this.data.get("location"))
  }

  skip() {
    event.stopPropagation()
  }
}
