function isInIosStandaloneMode() {
  return "standalone" in window.navigator && window.navigator.standalone
}

// All we can really check is whether we are in standalone mode.
export default function() {
  if (global.helpers.isIosSafari()) {
    return isInIosStandaloneMode()
  }

  return window.matchMedia("(display-mode: standalone)").matches
}
