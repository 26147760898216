import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "highlight", "toggler" ]
  static classes = [ "supported" ]

  connect() {
    this.toggle()
  }

  toggle() {
    if (this.data.get("togglertype") === "check_box") {
      if (this.togglerTarget.checked) {
        this.highlightTarget.classList.add(this.supportedClass)
      } else {
        this.highlightTarget.classList.remove(this.supportedClass)
      }
    }
  }
}
