export default function(progress, allPaid, adjustment = 5) {
  if (allPaid) return 100
  if (progress == 0) return 0

  const maxAdjusted = 100 - adjustment
  if (progress < adjustment) return adjustment
  if (progress > maxAdjusted) return maxAdjusted

  return progress
}
