import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "leftButton", "rightButton"]
  static values = {
    scrollBy: String,
    displayScrollbars: Boolean
  }

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === "overflows") {
        if (this.state.overflows) {
          this.contentTarget.classList.add("horizontal-scroller__content--navigation-visible")
          this.leftButtonTarget.classList.add("horizontal-scroller__left-button--navigation-visible")
          this.rightButtonTarget.classList.add("horizontal-scroller__right-button--navigation-visible")
        } else {
          this.contentTarget.classList.remove("horizonal-scroller__content--navigation-visible")
          this.leftButtonTarget.classList.remove("horizontal-scroller__left-button--navigation-visible")
          this.rightButtonTarget.classList.remove("horizontal-scroller__right-button--navigation-visible")
        }
      }

      return true
    }
  }

  connect() {
    if ("IntersectionObserver" in window) {
      const bindedHandleVisibilityChange = this.handleVisibilityChange.bind(this)
      this.visibilityObserver = new IntersectionObserver(bindedHandleVisibilityChange)
      this.visibilityObserver.observe(this.contentTarget)
    }
  }

  disconnect() {
    if ("IntersectionObserver" in window){
      this.visibilityObserver.disconnect()
      this.visibilityObserver = null
    }
  }

  initialize() {
    this.state = new Proxy({}, this.renderer)

    if (!this.displayScrollbars) {
      this.contentTarget.classList.add("horizontal-scroller__content")
    }
    this.leftButtonTarget.classList.add("horizontal-scroller__left-button")
    this.rightButtonTarget.classList.add("horizontal-scroller__right-button")

    const action = this.contentTarget.dataset.action
    this.contentTarget.dataset.action = `${action} resize@window->horizontal-scroller#layout`

    this.layout()
  }

  handleVisibilityChange(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.layout()
      }
    })
  }

  layout(event) {
    this.state.overflows = this.isOverflowing
  }

  scrollLeft(event) {
    event.preventDefault()

    this.contentTarget.scrollTo({
      left: this.contentTarget.scrollLeft - this.scrollAmount,
      behavior: "smooth"
    })
  }

  scrollRight(event) {
    event.preventDefault()

    this.contentTarget.scrollTo({
      left: this.contentTarget.scrollLeft + this.scrollAmount,
      behavior: "smooth"
    })
  }

  get isOverflowing() {
    return this.contentTarget.scrollHeight > this.contentTarget.clientHeight ||
      this.contentTarget.scrollWidth > this.contentTarget.clientWidth
  }

  get scrollAmount() {
    const scrollBy = parseInt(this.scrollBy)

    if (this.scrollByUnit === "%") return this.contentTarget.offsetWidth * (scrollBy / 100)

    return scrollBy
  }

  get scrollBy() {
    return this.scrollByValue || "100"
  }

  get displayScrollbars() {
    return this.displayScrollbarsValue || false
  }

  get scrollByUnit() {
    const scrollByUnit = this.scrollByUnits.find(unit => this.scrollBy.toString().endsWith(unit))

    if (scrollByUnit) return scrollByUnit

    return this.scrollByUnits[0]
  }

  get scrollByUnits() {
    return ["px", "%"]
  }
}
