var showSpinner = (event) => {
  console.log("show spinner")
  if (!!document.querySelector(".modal form") && !!document.querySelector(".modal").offsetWidth) {
    document.querySelector("#mySpinner").style.display = "block"
    document.querySelector(".modal form").style.display = "none"
  } else {
    document.querySelector("#loading-indicator").style.display = "block"
  }
}

var clearSpinner = (event) => {
  console.log("clear spinner")
  document.querySelector("#loading-indicator").style.display = "none"

  if (!!document.querySelector(".modal form") && !!document.querySelector(".modal").offsetWidth) {
    document.querySelector("#mySpinner").style.display = "none"
    document.querySelector(".modal form").style.display = "block"
  }
}

var triggerTurbolinksLoadEvent = (event) => {
  const [, , xhr] = event.detail

  console.log("trigger turbolinks load event")
  if (xhr.status == 200)
    // As we move over all Javascript to Stimulus, explicitly calling turbolinks:load after
    // ajax:success events to run ready() will be unnecessary
    {return document.dispatchEvent(new Event("turbolinks:load"))}
}

var disableLink = (event) => {
  console.log("disable link")
  const element = event.target

  if (element.hasAttribute("data-autodisable")) {
    return
  }

  console.log("disable link")
  if(!element.href.startsWith("#")) {
    element.setAttribute("disabled", true)
    element.setAttribute("data-reenable-after-load", true)
  }
}

document.addEventListener("ajax:complete", (event) => {
  const [xhr, ] = event.detail

  if (xhr.status == 200) {
    return
  }

  if (xhr.status == 500) {
    alert(`Server Error #${xhr.status}.\nThere was an error processing the page. Please report the bug to our team with reference 'Error 500'!`)
  } else if (xhr.status == 404) {
    alert("404 page not found! Try visiting the homepage and trying again?")
  } else if (xhr.status == 401 || xhr.status == 403) {
    alert("You are not authorised to visit this page. You may not have permission to access this page or you may not be logged in")
  } else if (xhr.status == 0) {
    alert("There is a problem with your internet connection. Please try again")
  } else {
    alert(`Error #${xhr.status}.\nThe server encountered an error processing your request. Please report the bug to our team!`)
  }

  event.stopImmediatePropagation()
})

document.addEventListener("turbolinks:load", () => {
  const forms = document.querySelectorAll("form[data-remote]")
  forms.forEach((element) => {
    element.removeEventListener("ajax:before", showSpinner)
    element.addEventListener("ajax:before", showSpinner)

    element.removeEventListener("ajax:success", clearSpinner)
    element.addEventListener("ajax:success", clearSpinner)

    element.removeEventListener("ajax:success", triggerTurbolinksLoadEvent)
    element.addEventListener("ajax:success", triggerTurbolinksLoadEvent)
  })

  const remoteLinks = document.querySelectorAll("a[href][data-remote]")
  remoteLinks.forEach((element) => {
    element.removeEventListener("ajax:beforeSend", disableLink)
    element.addEventListener("ajax:beforeSend", disableLink)

    element.removeEventListener("ajax:before", showSpinner)
    element.addEventListener("ajax:before", showSpinner)

    element.removeEventListener("ajax:success", clearSpinner)
    element.addEventListener("ajax:success", clearSpinner)

    element.removeEventListener("ajax:success", triggerTurbolinksLoadEvent)
    element.addEventListener("ajax:success", triggerTurbolinksLoadEvent)
  })

  const otherLinks = document.querySelectorAll("a[href]:not([data-remote]):not([data-autodisable]):not([target]):not([href='#'])")
  otherLinks.forEach((element) => {
    element.removeEventListener("click", disableLink)
    element.addEventListener("click", disableLink)
  })

  const autodisabledLinks = document.querySelectorAll("a[href][data-reenable-after-load]")
  autodisabledLinks.forEach((element) => {
    element.removeAttribute("data-reenable-after-load")
    element.removeAttribute("disabled")
  })
})
