import { Controller } from "@hotwired/stimulus"
import * as chrono from "chrono-node"

export default class extends Controller {
  static targets = ["calendar", "yearPicker", "monthPicker"]

  static values = {
    date: String,
    rangeSelection: Boolean,
    extendYearDropdownBy: Number
  }

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === "date" || property === "page") this.renderer.renderCalendar()
      if (property === "highlight") this.renderer.highlight()

      return true
    },

    renderCalendar: () => {
      const start = global.moment(this.state.date).add(this.state.page, "month").startOf("month")
      const end = global.moment(this.state.date).add(this.state.page, "month").endOf("month")

      // Calculate the first day of the grid (aligned to the new week start)
      const startDayOfWeek = (start.day() + 7 - this.weekStartDay) % 7 // Adjust to week start
      const firstGridDate = global.moment(start).subtract(startDayOfWeek, "days")
      const totalGridDays = 42 // 6 weeks * 7 days

      this.calendarTarget.querySelectorAll("tbody td").forEach(element => {
        element.innerHTML = ""
        element.dataset.date = ""
        element.dataset.action = ""
      })

      // Fill the 6x7 grid
      for (let i = 0; i < totalGridDays; i++) {
        const date = global.moment(firstGridDate).add(i, "days")
        let monthType = "current"

        if (date.isBefore(start, "month")) {
          monthType = "previous"
        } else if (date.isAfter(end, "month")) {
          monthType = "next"
        }

        this.renderer.renderDay(date, monthType)
      }

      const yearStart = global.moment(start).startOf("year").subtract(this.extendYearDropdownByValue, "year")
      const yearEnd = global.moment(start).endOf("year").add(this.extendYearDropdownByValue, "year")
      const yearRange = global.moment.range(yearStart, yearEnd)
      const years = Array.from(yearRange.by("years")).map(m => m.format("YYYY"))

      this.yearPickerTarget.innerHTML = ""

      years.forEach(year => {
        const option = document.createElement("option")
        option.value = year
        option.innerHTML = year

        this.yearPickerTarget.appendChild(option)
      })

      this.yearPickerTarget.value = global.moment(start).format("YYYY")
      this.monthPickerTarget.value = global.moment(start).format("M")

      if (this.state.highlight) this.renderer.highlight()
    },

    highlight: () => {
      const start = global.moment(this.state.highlight.start)
      const end = global.moment(this.state.highlight.end)

      this.calendarTarget.querySelectorAll(".date-picker__day-button").forEach( element => {
        element.classList.remove("date-picker__day-button--highlighted")
        element.classList.remove("date-picker__day-button--highlighted-start")
        element.classList.remove("date-picker__day-button--highlighted-end")
      })

      const range = global.moment().range(start, end)
      const dates = Array.from(range.by("days"))

      dates.forEach((date) => {
        if (!this.renderer.isVisible(date)) return

        const column = this.renderer.findDayColumn(date)
        const datePickerElement = column.querySelector(".date-picker__day-button")

        datePickerElement.classList.add("date-picker__day-button--highlighted")
      })

      if (this.renderer.isVisible(start)) {
        const startColumn = this.renderer.findDayColumn(start)
        const startDatePickerElement = startColumn.querySelector(".date-picker__day-button")
        startDatePickerElement.classList.add("date-picker__day-button--highlighted-start")
      }

      if (this.renderer.isVisible(end)) {
        const endColumn = this.renderer.findDayColumn(end)
        const endDatePickerElement = endColumn.querySelector(".date-picker__day-button")
        endDatePickerElement.classList.add("date-picker__day-button--highlighted-end")
      }
    },

    renderDay: (date, monthType = "current") => {
      const currentDate = global.moment().format(this.dateFormat)
      const formattedDate = global.moment(date).format(this.dateFormat)
      const datasetDate = `${global.moment(date).format(this.dateFormat)}`
      const column = this.renderer.findDayColumn(date)
      const datePickerElement = document.createElement("button")
      datePickerElement.classList.add("date-picker__day-button")
      datePickerElement.innerText = global.moment(date).format("D")
      datePickerElement.dataset.date = datasetDate
      datePickerElement.dataset.action = "click->date-picker#select"
      datePickerElement.classList.add(`date-picker__day-button--${monthType}-month`)

      if (currentDate == formattedDate) datePickerElement.classList.add("date-picker__day-button--today")

      column.innerHTML = datePickerElement.outerHTML
    },

    findDayColumn: (date) => {
      const coordinates = this.getCoordinates(date)
      const row = this.element.querySelector(`[data-date-picker-row="${coordinates.row}"]`)
      const column = row.querySelector(`[data-date-picker-column="${coordinates.column}"]`)

      return column
    },

    isVisible: (date) => {
      const coordinates = this.getCoordinates(date)
      const row = this.element.querySelector(`[data-date-picker-row="${coordinates.row}"]`)
      if (!row) return false

      const column = row.querySelector(`[data-date-picker-column="${coordinates.column}"]`)

      return !!column
    }
  }

  connect() {
    this.state = new Proxy({}, this.renderer)
    this.state.date = this.parseDate(this.dateValue)
    this.state.page = 0
    this.state.years

    this.selectionStart = null
    this.emitEvent("connect")
  }

  currentMonth() {
    this.state.date = new Date()
    this.state.page = 0
    this.emitEvent("navigate")
  }

  nextMonth() {
    this.state.page += 1
    this.emitEvent("navigate")
  }

  previousMonth() {
    this.state.page -= 1
    this.emitEvent("navigate")
  }

  select(event) {
    if (this.isRangeSelection) return this.selectRange(event)

    this.selectSingle(event)
  }

  selectSingle(event) {
    this.selection = { start: event.currentTarget.dataset.date, end: event.currentTarget.dataset.date }
    this.emitEvent("select")
  }

  selectRange(event) {
    if (this.selectionStart === null) {
      this.selectionStart = event.currentTarget.dataset.date
      this.selection = { start: this.selectionStart, end: this.selectionStart }

      return
    }

    const start = global.moment(this.selectionStart)
    const end = global.moment(event.currentTarget.dataset.date)

    if (start.isBefore(end)) {
      this.selection = { start: this.selectionStart, end: event.currentTarget.dataset.date }
    } else {
      this.selection = { start: event.currentTarget.dataset.date, end: this.selectionStart }
    }

    this.selectionStart = null
    this.emitEvent("select")
  }

  navigate() {
    const month = String(this.monthPickerTarget.value).padStart(2, "0")
    const year = this.yearPickerTarget.value
    const date = `${year}-${month}-01`

    this.state.date = global.moment(date)
    this.state.page = 0
    this.emitEvent("navigate")
  }

  getCoordinates(date) {
    // Calculate the first day of the grid
    const start = global.moment(this.state.date).add(this.state.page, "month").startOf("month")
    const startDayOfWeek = (start.day() + 7 - this.weekStartDay) % 7 // Adjust to week start
    const firstGridDate = global.moment(start).subtract(startDayOfWeek, "days")

    // Calculate the offset from the first grid date
    const daysOffset = date.diff(firstGridDate, "days")

    // Calculate row and column
    const coordinates = {}
    coordinates.row = Math.floor(daysOffset / 7)
    coordinates.column = daysOffset % 7

    return coordinates
  }

  parseDate(date = null) {
    const parsedDate = chrono.parseDate(date)
    const parsedMoment = parsedDate ? global.moment(parsedDate) : global.moment()

    return parsedMoment.format(this.dateFormat)
  }

  emitEvent(eventType) {
    const customEvent = new CustomEvent(`date-picker:${eventType}`, {
      bubbles: false,
      detail: this.state
    })
    this.element.dispatchEvent(customEvent)
  }

  set selection(selection) {
    this.state.selection = selection
    this.state.highlight = this.state.selection
  }

  get selection() {
    return this.state.selection
  }

  set highlight(highlight) {
    this.state.highlight = highlight
  }

  get highlight() {
    return this.state.highlight
  }

  get dateFormat() {
    return "YYYY-MM-DD"
  }

  get isRangeSelection() {
    return this.rangeSelectionValue === true
  }

  get weekStartDay() {
    return 1 // 1 represents Monday
  }
}
