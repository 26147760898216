export default function() {
  document.querySelectorAll("[data-controller='dialog']").forEach((dialog) => {
    var controller = global.application.getControllerForElementAndIdentifier(
      dialog, "dialog"
    )

    if (controller) {
      controller.close()
    }
  })
}
