import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submit", "button"]
  static classes = ["hide"]
  static values = {
    visible: Boolean,
    submitDisabled: Boolean
  }

  connect() {
    if (this.visibleValue) {
      this.show()
    }
    if (this.submitDisabledValue) {
      this.disableSubmit()
    }
  }

  toggle() {
    if (this.visible) {
      this.hide()
    } else {
      this.show()
    }
  }

  show() {
    this.submitTarget.classList.remove(this.hideClass)
  }

  hide() {
    this.submitTarget.classList.add(this.hideClass)
  }

  disableSubmit() {
    this.button.disabled = true
    this.button.classList.add("button--disabled")
  }

  enableSubmit() {
    this.button.disabled = false
    this.button.classList.remove("button--disabled")
  }

  get visible() {
    return !this.submitTarget.classList.contains(this.hideClass)
  }

  get button() {
    return this.element.querySelector(".button")
  }
}
