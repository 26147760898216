import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    selectedView: String, // NOTE: will be useful after gallery view is also created
    files: Array,
    multiple: Boolean,
    max: Number,
    mimeTypes: String,
    maxFilesSelectedNotice: String,
    invalidFileTypeNotice: String,
    disabled: Boolean
  }
  static targets = [
    "field", "container", "description", "selectedViewSimpleSingle",
    "selectedViewSimpleMultiple"
  ]

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === "attachedFiles") {
        this.renderer.showMaxFilesNotification(value)
      } else if (property === "invalidFiles") {
        this.renderer.showInvalidFilesNotification(value)
      } else if (property === "files") {
        this.renderer.updateSelectedView(value)
      }

      return true
    },
    showMaxFilesNotification: (files) => {
      if (files.length <= this.maxValue) return

      window.helpers.notify(this.maxFilesSelectedNoticeValue, {type: "error"})
    },
    showInvalidFilesNotification: (files) => {
      if (files.length === 0) return

      window.helpers.notify(this.invalidFileTypeNoticeValue, {type: "error"})
    },
    updateSelectedView: (files) => {
      switch (this.selectedViewValue) {
        case "simple":
          this.renderer.updateSelectedViewSimple(files.length)
          break
        // NOTE: A second case, which we know would definitely arise, would be a gallery view
        default:
          break
      }
    },
    updateSelectedViewSimple: (count) => {
      this.descriptionTarget.style.display = "none"
      this.selectedViewSimpleSingleTarget.style.display = "none"
      this.selectedViewSimpleMultipleTarget.style.display = "none"

      if (count === 0) {
        this.descriptionTarget.style.display = "block"
      } else if (count === 1) {
        this.selectedViewSimpleSingleTarget.style.display = "block"
      } else {
        this.selectedViewSimpleMultipleTarget.style.display = "block"
        this.selectedViewSimpleMultipleText.textContent =
          this.selectedViewSimpleMultipleText.textContent.replace(/\d/g, count)
      }
    }
  }

  connect() {
    // Ref: https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#specifying_drop_targets
    // Ref: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      this.containerTarget.addEventListener(eventName, (event) => {
        event.preventDefault()
        event.stopPropagation()
      })
    })

    // To disable the click event to propagate up to the containerTarget again
    // when it is programmatically called in the click handler below.
    this.fieldTarget.addEventListener("click", (event) => event.stopPropagation())
    this.state = new Proxy({}, this.renderer)

    this.state.files = this.filesValue
  }

  attachDroppedFiles(event) {
    if (this.disabledValue) return

    const eventFiles = event.dataTransfer.files
    this.fieldTarget.files = eventFiles
    // Since we are programmatically changing the target's files, we should
    // manually send the "change" event for anyone else listening for it, along
    // with our own listener
    this.fieldTarget.dispatchEvent(new Event("change"))
  }

  openFileBrowser() {
    if (this.disabledValue) return

    this.fieldTarget.click()
  }

  sanitizeAttachedFiles() {
    if (this.disabledValue) return

    const files = this.fieldTarget.files

    this.state.attachedFiles = files

    const dataTransfer = new DataTransfer()
    const invalidFiles = []
    for (const file of files) {
      if (!this.validType(file)) {
        invalidFiles.push(file)
      } else if (dataTransfer.items.length === this.maxValue) {
        continue
      } else {
        dataTransfer.items.add(file)
      }
    }
    this.state.invalidFiles = invalidFiles
    this.fieldTarget.files = dataTransfer.files

    this.state.files = dataTransfer.files
  }

  validType(file) {
    return this.mimeTypesValue === "*/*" || this.mimeTypes.includes(file.type)
  }

  get filesCount() {
    return this.fieldTarget.files.length
  }

  get mimeTypes() {
    return this.mimeTypesValue.split(", ")
  }

  get selectedViewSimpleMultipleText() {
    return this.selectedViewSimpleMultipleTarget.getElementsByClassName("file-upload__description")[0]
  }
}
