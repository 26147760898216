import { Controller } from "@hotwired/stimulus"
import { Renderer } from "models/schedule"

const DATA = {
  "2022-10-04": [
    {
      startTime: "2022-10-04T09:30:00+02:00",
      endTime: "2022-10-04T10:00:00+02:00",
      referenceId: 1,
      title: "Event 1",
      color: "#fbe8fc"
    },
    {
      startTime: "2022-10-04T10:30:00+02:00",
      endTime: "2022-10-04T11:30:00+02:00",
      referenceId: 2,
      title: "Event 2",
      color: "#fbe8fc"
    },
    {
      startTime: "2022-10-04T12:00:00+02:00",
      endTime: "2022-10-04T13:00:00+02:00",
      referenceId: 3,
      title: "Event 3",
      color: "#fbe8fc"
    },
    {
      startTime: "2022-10-04T12:45:00+02:00",
      endTime: "2022-10-04T13:45:00+02:00",
      referenceId: 4,
      title: "Event 4",
      color: "#fbe8fc"
    },
    {
      startTime: "2022-10-04T13:30:00+02:00",
      endTime: "2022-10-04T14:30:00+02:00",
      referenceId: 5,
      title: "Event 5",
      color: "#fbe8fc"
    }
  ],
  "2022-10-30": [
    {
      startTime: "2022-10-30T00:00:00+02:00",
      endTime: "2022-10-30T00:30:00+02:00",
      referenceId: 1,
      title: "Event 1",
      color: "#fbe8fc"
    },
    {
      startTime: "2022-10-30T01:00:00+02:00",
      endTime: "2022-10-30T01:30:00+02:00",
      referenceId: 2,
      title: "Event 2",
      color: "#fbe8fc"
    },
    {
      startTime: "2022-10-30T02:00:00+02:00",
      endTime: "2022-10-30T02:30:00+02:00",
      referenceId: 3,
      title: "Event 3",
      color: "#fbe8fc"
    },
    {
      startTime: "2022-10-30T02:00:00+01:00",
      endTime: "2022-10-30T02:30:00+01:00",
      referenceId: 4,
      title: "Event 4",
      color: "#fbe8fc"
    },
    {
      startTime: "2022-10-30T03:00:00+01:00",
      endTime: "2022-10-30T03:30:00+01:00",
      referenceId: 5,
      title: "Event 5",
      color: "#fbe8fc"
    },
    {
      startTime: "2022-10-30T04:00:00+01:00",
      endTime: "2022-10-29T04:30:00+01:00",
      referenceId: 6,
      title: "Event 6",
      color: "#fbe8fc"
    }
  ]
}

export default class extends Controller {
  connect() {
    if (this.data) {
      const renderer = new Renderer(this.data, { eventClass: this.eventClassName })
      renderer.render(element => this.element.appendChild(element))
    }
  }

  get date() {
    return this.element.dataset.date
  }

  get eventClassName() {
    return this.element.dataset.eventClassName
  }

  get data() {
    return DATA[this.date]
  }
}
