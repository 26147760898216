import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["target"]

  click(_event) {
    if (!this.targetTarget) { return }

    this.targetTarget.click()
  }
}
