import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { selector: String }

  remove(_event) {
    if (!this.targetElement) { return }

    this.targetElement.remove()
  }

  get targetElement() {
    return document.querySelector(this.selectorValue)
  }
}
