import cable from "./action_cable"

function onCableConnected(controller) {
  if(controller.onCableConnected){
    controller.onCableConnected()
  }
}

function onCableDisconnected(controller, storageIdentifier) {
  const currentSubscription = loadSubscriptionIdentifier(controller, storageIdentifier)
  if (currentSubscription) {
    localStorage.removeItem("actionCableSubscriptions")
  }
  if(controller.onCableDisconnected){
    controller.onCableDisconnected()
  }
}

function onCableReceived(controller, data) {
  if(controller.onCableReceived){
    controller.onCableReceived(data)
  }
}

function persistSubscription(controller, storageIdentifier, subscription) {
  const currentStorage = JSON.parse(localStorage.getItem("actionCableSubscriptions")) || {}
  currentStorage[`${controller.identifier}-${storageIdentifier}`] = subscription.identifier
  localStorage.setItem("actionCableSubscriptions", JSON.stringify(currentStorage))
}

function loadSubscriptionIdentifier(controller, storageIdentifier) {
  const currentStorage = JSON.parse(localStorage.getItem("actionCableSubscriptions")) || {}
  return currentStorage[`${controller.identifier}-${storageIdentifier}`]
}

function removeSubscriptionIdentifier(controller, storageIdentifier) {
  const currentStorage = JSON.parse(localStorage.getItem("actionCableSubscriptions")) || {}
  delete currentStorage[`${controller.identifier}-${storageIdentifier}`]
  localStorage.setItem("actionCableSubscriptions", JSON.stringify(currentStorage))
}

function loadSubscription(controller, storageIdentifier) {
  const subscriptionIdentifier = loadSubscriptionIdentifier(controller, storageIdentifier)
  return cable.subscriptions.findAll(subscriptionIdentifier)[0]
}

function createSubscription(controller, storageIdentifier, subscriptionChannel, subscriptionCallbacks) {
  let subscription = loadSubscription(controller, storageIdentifier)
  if(subscription) return subscription

  subscription = cable.subscriptions.create(subscriptionChannel, subscriptionCallbacks)
  persistSubscription(controller, storageIdentifier, subscription)
  return subscription
}

export function useActionCable(controller, options) {
  function subscribe() {
    const subscriptionChannel = { channel: options.channel, ...options.channelArgs }
    // If storageIdentifier is not provided, use channel as the identifier (for backward compatibility with current notification channels)
    const storageIdentifier = options.storageIdentifier || options.channel
    createSubscription(controller, storageIdentifier, subscriptionChannel, {
      connected: () => {
        onCableConnected(controller)
      },
      disconnected: () => {
        onCableDisconnected(controller, options.storageIdentifier)
      },
      received: (data) => {
        onCableReceived(controller, data)
      }
    })
  }

  function currentSubscription() {
    if (!options || !options.channel) return null

    return loadSubscription(controller, options.storageIdentifier)
  }

  function unsubscribe() {
    const subscription = currentSubscription()

    if (subscription) {
      subscription.unsubscribe()
      removeSubscriptionIdentifier(controller, options.storageIdentifier)
    }
  }

  function unsubscribeAll() {
    const storageIdentifier = options.storageIdentifier || options.channel
    const subscriptions = cable.subscriptions.subscriptions
    const subscriptionsToRemove = subscriptions.filter(subscription => subscription.identifier.includes(controller.identifier))
    subscriptionsToRemove.forEach(subscription => {
      removeSubscriptionIdentifier(controller, storageIdentifier)
      subscription.unsubscribe()
    })
  }

  function perform(action, data) {
    const subscription = currentSubscription()

    if (subscription) {
      subscription.perform(action, data)
    }
  }

  if (options && options.channel) {
    subscribe()
  }

  return {
    subscribe,
    unsubscribe,
    unsubscribeAll,
    perform,
    subscription: currentSubscription()
  }
}
