import { Controller } from "@hotwired/stimulus"
const debounce = require("lodash.debounce")

const ALWAYS_VISIBLE_OPTIONS_LIMIT = 25
const MAX_VISIBLE_OPTIONS_DEFAULT = 4

export default class extends Controller {
  static classes = ["dropdownVisible", "optionHidden", "optionsHidden", "optionHighlighted"]
  static targets = ["action", "dropdown", "input", "option", "options", "optionTemplate"]
  static values = {
    attribute: String,
    maxVisibleOptions: Number,
    minInputLengthToShowOptions: Number,
    optionsFetchError: String,
    url: String
  }

  initialize() {
    // Need to add debounce since we use this component with data containing 1000s
    // or even 10s of thousands of options. In such cases, filterAndShowOptions
    // becomes the bottleneck as it has to loop through all those options in
    // case the match is not found, ON EVERY KEYSTROKE.
    this.filterAndShowOptions = debounce(this.filterAndShowOptions, 200).bind(this)
  }

  connect() {
    this.maxVisibleOptionsValue = this.maxVisibleOptionsValue || MAX_VISIBLE_OPTIONS_DEFAULT
    this.optionsData = []
    if (this.hasUrlValue) {
      fetch(this.urlValue)
        .then(response => {
          // Since this is never going to be a user error, we'll show a predefined error
          if (!response.ok) {
            throw new Error(response)
          }
          return response.json()
        })
        .then((data) => {
          this.optionsData = data
          // We've had to save this as separate data for performance reasons.
          this.optionsDataNormalised = data.map(value => global.helpers.comparableString(value))

          // create options
          for (let i = 0; i < this.maxVisibleOptionsValue && i < this.optionsData.length; i++) {
            const optionNode = this.optionTemplateTarget.content.firstElementChild.cloneNode(true)
            this.optionsTarget.appendChild(optionNode)
          }
          // If the input is focused, we need to show the options
          if (document.activeElement == this.inputTarget) {
            this.filterAndShowOptions({ target: this.inputTarget })
          }
        }).catch((error) => {
          this.showOptionsFetchError()
          console.log(error)

          // If the input is focused, and we have an action
          if (document.activeElement == this.inputTarget && this.hasActionTarget) {
            this.filterAndShowOptions({ target: this.inputTarget })
          }
        })
    }
  }

  dismissOptions(event) {
    if (event && event.target == this.inputTarget) {
      // This is to prevent dismissing the options when the user clicks on the input
      return
    }

    this.visibleOptions.forEach((option) => {
      option.classList.remove(this.optionHighlightedClass)
      option.classList.add(this.optionHiddenClass)
    })
    this.dropdownTarget.classList.remove(this.dropdownVisibleClass)
  }

  filterAndShowOptions(event) {
    const hasOptions = this.optionsData.length !== 0
    let visible = 0
    const text = global.helpers.comparableString(event.target.value)
    const matchFound = this.optionsDataNormalised.includes(text)

    if (hasOptions) {
      for (const option of this.optionTargets) {
        option.classList.add(this.optionHiddenClass)
        option.classList.remove(this.optionHighlightedClass)
      }

      let showOptions = false

      if (!matchFound) {
        // When not set, this value's nil value gets converted to NaN in javascript.
        if (this.hasMinInputLengthToShowOptionsValue && !Number.isNaN(this.minInputLengthToShowOptionsValue)) {
          showOptions = text.length >= this.minInputLengthToShowOptionsValue
          // This else clause is to ensure existing functionality works.
        } else {
          // Can extract this thing out into an option on the component, but for now we want it to always
          // happen so keeping it here.
          const showOptionsIfNotMany = this.optionsData.length < ALWAYS_VISIBLE_OPTIONS_LIMIT || text.length > 0
          showOptions = showOptionsIfNotMany
        }
      }

      if (showOptions) {
        let optionIndex = 0
        for (let i = 0; i < this.optionsData.length; i++) {
          if (optionIndex >= this.optionTargets.length) break

          const valueLowerCase = this.optionsDataNormalised[i]
          if (!valueLowerCase.includes(text)) continue

          const option = this.optionTargets[optionIndex]
          if (optionIndex === 0) {
            option.classList.add(this.optionHighlightedClass)
          }

          const value = this.optionsData[i]
          option.innerText = value
          option.classList.remove(this.optionHiddenClass)
          visible += 1
          optionIndex += 1
        }
      }
    }

    if (visible === 0) {
      this.optionsTarget.classList.add(this.optionsHiddenClass)
    } else {
      this.optionsTarget.classList.remove(this.optionsHiddenClass)
    }

    // if action exists, show dropdown unless an option is selected
    if (visible === 0 && (!this.hasActionTarget || matchFound)) {
      this.dropdownTarget.classList.remove(this.dropdownVisibleClass)
    } else {
      this.dropdownTarget.classList.add(this.dropdownVisibleClass)
      this.shiftOptionsPosition()
    }
  }

  navigateOptions(event) {
    const { upKey, downKey, enterKey } = global.helpers.keyCodes

    if (event.keyCode !== upKey && event.keyCode !== downKey && event.keyCode !== enterKey) {
      return
    }

    const visibleOptions = this.visibleOptions

    // No visible options, submit the form on press of enter
    if (visibleOptions.length === 0) {
      if (event.keyCode == enterKey) {
        // Otherwise on pressing enter, the default action of textarea occurs, which adds
        // a newline.
        event.preventDefault()

        this.submitForm(event.target.form)
      }
      return
    }

    const currentOptionIndex = visibleOptions.findIndex((option) => {
      return option.classList.contains(this.optionHighlightedClass)
    })

    event.preventDefault()

    let nextOptionIndex = currentOptionIndex
    switch (event.keyCode) {
      case upKey:
        if (currentOptionIndex == 0) {
          return
        }
        nextOptionIndex = currentOptionIndex - 1
        break
      case downKey:
        if (currentOptionIndex == visibleOptions.length - 1) {
          return
        }
        nextOptionIndex = currentOptionIndex + 1
        break
      case enterKey:
        visibleOptions[currentOptionIndex].click()
        return
    }

    visibleOptions[currentOptionIndex].classList.remove(this.optionHighlightedClass)
    this.highlightNewOption(nextOptionIndex, visibleOptions)
  }

  highlight(event) {
    const visibleOptions = this.visibleOptions

    const currentOption = visibleOptions.find((option) => option.classList.contains(this.optionHighlightedClass))
    currentOption.classList.remove(this.optionHighlightedClass)

    this.highlightNewOption(visibleOptions.indexOf(event.target), visibleOptions)
  }

  pick(event) {
    this.inputTarget.value = event.target.innerText.trim()
    this.dismissOptions()
  }

  showOptionsFetchError() {
    this.recordEditorController.submit({ detail: [this.optionsFetchErrorData, null, null] })
  }

  // visibleOptions is passed for performance reasons
  highlightNewOption(nextOptionIndex, visibleOptions) {
    if (nextOptionIndex < 0 || nextOptionIndex >= visibleOptions.length) {
      return
    }

    visibleOptions[nextOptionIndex].classList.add(this.optionHighlightedClass)
    global.helpers.scrollToOption(visibleOptions[nextOptionIndex], this.optionsTarget)
  }

  shiftOptionsPosition() {
    if (!this.dropdownTarget.classList.contains(this.dropdownVisibleClass)) {
      return
    }

    const { top: inputTop, bottom: inputBottom } = this.inputTarget.getBoundingClientRect()
    const optionsHeight = this.dropdownTarget.clientHeight

    const notEnoughSpaceAbove = inputTop - optionsHeight < 0
    const noCutoffBelow = inputBottom + optionsHeight < window.innerHeight
    if (notEnoughSpaceAbove || noCutoffBelow) {
      this.dropdownTarget.style.bottom = null
    } else if (!this.dropdownTarget.style.bottom) { // If it should show on top of the input but doesn't
      const formFieldHeight = this.formFieldElement.clientHeight
      this.dropdownTarget.style.bottom = `${formFieldHeight}px`
    }
  }

  submitForm(form) {
    // add-barber--services controller has the reasoning behind this.
    const submitButton = form.querySelector("button[type=\"submit\"]")
    if (submitButton) {
      submitButton.click()
    } else if (form.requestSubmit) {
      form.requestSubmit()
    } else {
      form.submit()
    }
  }

  get visibleOptions() {
    return this.optionTargets.filter((option) => !option.classList.contains(this.optionHiddenClass))
  }

  get recordEditorController() {
    return global.application.getControllerForElementAndIdentifier(
      this.element.closest(".record-editor"), "record-editor"
    )
  }

  get optionsFetchErrorData() {
    return { errors: { [this.attributeValue]: [this.optionsFetchErrorValue] } }
  }

  get formFieldElement() {
    return this.element.closest(".form-field")
  }
}
