import StackManager from "models/stack_manager"

class Stackable {
  constructor(controller, options) {
    this.controller = controller
    this.stackName = options.stackName
  }

  attach() {
    this._onSheetRemovalBind = this._onSheetRemoval.bind(this)
    this._onSheetReloadBind = this._onSheetReload.bind(this)
    this.controller.element.addEventListener(`stack-${this.stackName}:reload`, this._onSheetReloadBind)
    this.controller.element.addEventListener("sheet-update:child-removal", this._onSheetRemovalBind)
    this._defineProxyGetters(this.controller, ["stackManager"])
  }

  detach() {
    this.controller.element.removeEventListener("sheet-update:child-removal", this._onSheetRemovalBind)
    this.controller.element.removeEventListener(`stack-${this.stackName}:reload`, this._onSheetReloadBind)
  }

  _onSheetReload(event) {
    if (this.controller.onSheetReload) {
      this.controller.onSheetReload(event.detail.node)
    }
    this.stackManager.finishReloading()
  }

  _onSheetRemoval(data) {
    if (this.controller.onSheetRemoval) {
      return this.controller.onSheetRemoval(data)
    }
  }

  _defineProxyGetters(target, proxyProperties) {
    proxyProperties.forEach(key => {
      Object.defineProperty(target, key, {
        get: () => this[key],
      })
    })
  }

  get stackManager() {
    if (this._stackManager) return this._stackManager

    this._stackManager = StackManager.loadFor(this.stackName)
    return this._stackManager
  }
}

export function useAsStackable(controller, options = {}) {
  function register() {
    const stackable = new Stackable(controller, options)
    stackable.attach()
  }

  function unregister() {
    const stackable = new Stackable(controller, options)
    stackable.detach()
  }

  if (!options.stackName) {
    throw new Error("Stack name is required")
  }

  register()

  return {
    register,
    unregister,
  }
}
